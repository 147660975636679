.login-container {
    background: url('../assets/img/background-login-blue.jpg') no-repeat;
    .card-login {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.5px);
        -webkit-backdrop-filter: blur(5.5px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        .btn {
            background-color: #0266a2;
            border-color: #1865b7;
        }
    }
}