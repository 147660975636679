body {
    background-color: #FAFBFE !important;
}
.sidebar {
    background-color: #1B2258 !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 0.9375rem !important;
    font-weight: bold !important;
    width: 250px !important;
}

.sidebar .nav {
    width: 250px !important;
}

.sidebar .sidebar-nav {
    width: 250px !important;
}

.sidebar .nav-dropdown .nav-link {
    color : #9093AD !important;
}




.sidebar .nav-dropdown.open .nav-link, .sidebar .nav-dropdown .nav-link.active,
.sidebar .nav-link.active .nav-icon 
{
    color : #DADBE4 !important;
}

.sidebar .nav-dropdown.open {
    background-color: transparent !important;
}

.sidebar .nav-dropdown .nav-link:hover {
    color : #fff !important;
    background-color: #383E6F !important;
}

@media (min-width: 992px) {
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
      margin-left: 250px !important;
    }
}


.card {
    border : 1px solid #D5DDE6 !important;
}

.app-header { 
    border : 1px solid #D5DDE6 !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}

.breadcrumb { 
    background-color: transparent !important;
    border-bottom: none !important;
    padding-left: 1.8rem !important;
    .breadcrumb-item {
        font-weight: 500;

        a {
            color: #364C66;
        }
    }
}