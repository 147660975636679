.container-absen {
    // background: url('../assets/img/bg-opm.jpg');
    // background-attachment: fixed;
    // background-size: cover;
    background-color : #fff !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0px 0px; 
    background-attachment: fixed;    
    width: 100%;
    // height: 100%;
    
    background-size: cover;
    background-repeat: no-repeat;
    width: 100v;
    height: 100%;    
    min-height: 100vh;

    .absen-title, .absen-title-secondary, .jumlah-peserta {
        // -webkit-text-stroke: 0.5px #fff; 
        text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255), 1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
        color : #274470 !important;
    }


    
    .background-logo {        
        width: fit-content;
        text-align: center;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;        
        img {
            width: 10rem;
        }
    }

    .content-absen {        
        background: rgba(73, 65, 65, 0.2);        
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(8.5px);
        -webkit-backdrop-filter: blur(5.5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding-left: 25px;
        padding-right: 25px;
        // min-height: 80vh;
        height: 100%;
        min-height: 100vh;
        .absen-title {
            font-size: 2.25rem;
            font-weight: bold;
            color: #3b82f6;
        }
        .white-text {
            color: #ffffff !important;
        }
        .blue-text {
            color: #3b82f6 !important;
        }
        .green-text {            
            color: #00661f !important;
        }
        .red-text {
            color: rgb(168, 2, 2) !important;
        }
        .absen-title-secondary {
            font-size: 1.5rem;
            font-weight: bold;
            color: #3b82f6;
        }
        .search-absen {
            margin-top : 20px;
        }        
        .table-absen {
            tbody tr td {
                background-color: #fff;
            }
            thead tr th {
                background-color: rgb(231, 231, 231);
            }
        }
        .absen-timer {
            font-size: 3.25rem;
            color: rgb(167, 35, 35);
            margin-top: 10px;
        }
        .absen-timer-title {
            font-size: 2.2rem;
            color: #3b82f6;
            font-weight: bold;
            line-height: 20px;
        }

        .table-absen tbody tr.active td {
            background-color: #3b82f6;
            color: #fff;
        }
    }
}